<script>
import { mapActions } from 'vuex'
export default {
  name: 'SolutionsOffers',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Tabs: () => import('@/components/general/Tabs'),
    Datatable: () => import('@/components/general/Datatable')
  },

  data () {
    return {
      tabLinks: [
        {
          location: { name: 'solutions.index' },
          text: this.$t('solutions.tabs:link.1')
        },
        {
          location: { name: 'solutions.types' },
          text: this.$t('solutions.tabs:link.2')
        },
        {
          location: { name: 'solutions.offers' },
          text: this.$t('solutions.tabs:link.3')
        }
      ],

      offers: []
    }
  },

  created () {
    this.getOfferList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetOfferList'
    ]),

    getOfferList () {
      this.setFetching(true)

      this.attemptGetOfferList().then(res => {
        this.offers = res.data
      }).finally(() => {
        this.setFetching(false)
      })
    }
  }
}
</script>

<template>
  <div class="main-content">
    <ContentHeader
      :title="$t('offers:header.title')"
      :description="$t('offers:header.description')"
      background="/assets/images/themes/default/png/solutions_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :index-active="1"
        :links="tabLinks"
      />
    </ContentHeader>

    <div class="center solutions-offers">
      <Datatable :items="offers">
        <template slot="thead">
          <tr>
            <th class="th">
              {{ $t('offers:datatable.header.1') }}
            </th>

            <th class="th text-center">
              {{ $t('offers:datatable.header.2') }}
            </th>

            <th class="th text-center" />
          </tr>
        </template>

        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <td class="td td-text">
              <span class="bolder">{{ props.item.name }}</span>
            </td>

            <td
              class="td text-center"
              width="120"
            >
              <span
                v-if="!!props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('global.status:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('global.status:inactive') }}</span>
            </td>
          </tr>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<style>
.solutions-offers {
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
}
.solutions-offers .datatable-wrapper {
  padding-top: 35px;
}
.solutions-offers table .td {
  border: none;
}

.solutions-offers .datatable tbody tr {
  padding: 5px 0;
  min-height: 60px;
  vertical-align: middle;
  border-bottom: 2px solid #EAEAEA;
}

.solutions-offers .datatable tbody td:first-child {
  line-height: 60px;
}
</style>
